/* eslint-disable max-len */
import React from 'react';
import { DEFAULTS } from '@kmdpartners/ui-kit/src/CN_Design_System/blocks/Footer/constants';
export const FOOTER_DATA = Object.assign(Object.assign({}, DEFAULTS), { title: 'A little about us', mainText: [
        'Since we began in 2018 we’ve offered financial solutions to over 275,000 borrowers. If you’re one of the many Americans in a difficult financial situation, CreditNinja’s online loans may be the answer you’ve been looking for. And our online application makes the entire process easier than ever. No more waiting in long lines or filling out a bunch of paperwork. Apply from the comfort of your own home, and if approved, you may receive the funds as soon as the same day.* Choose a safe and reliable financial solution today',
        'Don’t let unplanned expenses ruin your budget. Apply for an online loan today with CreditNinja, and find out how we can help you.'
    ], disclosure: [
        '*Not all loan requests are approved. Approval and loan terms vary based on credit determination and state law. Applications approved before 10:30 a.m. CT Monday – Friday are generally funded the same business day. Applications approved after this time are generally funded the next business day. Some applications may require additional verification, in which case, the loan if approved, will be funded the business day after such additional verification is completed.',
        '†MILITARY LENDING ACT COVERAGE. Because all the loans CreditNinja makes or arranges exceed the Military Lending Act’s 36% MAPR limit, CreditNinja does not offer loans to individuals covered by the MLA. To determine whether an individual is covered the MLA, we obtain a report from a national consumer reporting agency. We also ask the above question as an added precaution to ensure compliance with the MLA.',
        'Under the MLA, “servicemember” generally means a regular or reserve member of the Army, Navy, Marine Corps, Air Force, or Coast Guard, serving on active duty under a call or order that does not specify a period of 30 days or fewer. “Dependent” generally means a servicemember’s spouse, child under age 18, or other individual for whom a servicemember provided more than half the individual’s support in the past 180 days.',
        React.createElement(React.Fragment, null,
            "If you want to check whether you are covered by the MLA, you may submit a single-record request on the official\u00A0",
            React.createElement("a", { href: 'https://mla.dmdc.osd.mil/mla/#/home' }, "MLA website"),
            "."),
        React.createElement(React.Fragment, null,
            "CreditNinja Lending, LLC NMLS ID# 1752769 | \u00A0",
            React.createElement("a", { href: 'https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1752769' }, "NMLS Consumer Access"),
            "."),
        'STATE LAW DISCLOSURES. California residents: Loans will be arranged or made pursuant to a California Finance Lenders Law License. Texas residents: CreditNinja operates as a Credit Access Business (#159810). Delaware residents: CreditNinja is licensed by the Delaware State Bank Commissioner pursuant to 5 Del. C. § 2201, et seq., and expires 12/31/2025.',
        'NOTICE OF FURNISHING NEGATIVE INFORMATION. We may report information about your account to credit bureaus. Late payments, missed payments, or other defaults on your account may be reflected in your credit report.',
        'LOAN DISBURSEMENT. Applications approved before 10:30 a.m. CT Monday-Friday are generally funded the same business day. Applications approved after this time are generally funded the next business day. Some applications may require additional verification, in which case the loan, if approved, will be funded the business day after such additional verification is completed.',
        'ACCEPTED PAYMENT METHODS FOR FEB LOANS. For loans made by First Electronic Bank, acceptable payment methods include ACH, debit card, personal check, money order, and cashier’s check. Cash not accepted.',
        'FEB Installment Loans are provided by First Electronic Bank, a Utah-chartered industrial bank located in Salt Lake City, Utah.',
        'CC Connect Installment Loans are provided by CC Connect, a division of Capital Community Bank, a Utah Chartered bank, located in Provo, Utah, Member FDIC.',
        React.createElement(React.Fragment, null,
            "Depending on your state of residence, applications submitted on this website may by originated by CC Connect, a division of Capital Community Bank, a Utah Chartered bank, located in Provo, Utah, Member FDIC. For more information, please visit our",
            ' ',
            React.createElement("a", { href: 'https://www.creditninja.com/ccconnect', target: '_self' }, "CC Connect"),
            ' ',
            "page."),
        React.createElement(React.Fragment, null,
            "HIGH COST DISCLOSURE. The loans made or arranged by CreditNinja have a high APR and are not recommended as a long-term financial solution. Additionally, these loans typically include a loan origination fee that is generally not refunded if you prepay the loan. Your choice of a loan product should match your needs and ability to repay. Consider carefully the amount, term, and finance charges for any loan. If you choose a high interest loan, reduce your finance charges by paying more than the minimum installment payment. Paying late increases your finance charges and may cause you to incur a late fee. Customers with credit difficulties should seek consumer credit counseling. For helpful resources concerning financial literacy, credit score trends, and a glossary of financial terms, please see the Resources tab at the top of our website,",
            ' ',
            React.createElement("a", { href: 'https://www.creditninja.com', target: '_self' }, "www.creditninja.com"),
            "."),
        'IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT. To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account. What this means for you: When you open an account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see your driver’s license or other identifying documents.'
    ] });
// --------------------------
// REDUX NAMESPACES
// --------------------------
export const START_SURVEY_ATTEMPT = 'home/START_SURVEY_ATTEMPT';
