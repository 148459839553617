/* eslint-disable max-len */
import React from 'react';
import YouTube from 'react-youtube';
import styles from './index.module.scss';
const opts = {
    width: '100%'
};
const scrollIntoFooterView = () => {
    document.getElementById('footer-links').scrollIntoView();
};
const AboutVideo = () => {
    return (React.createElement("section", { id: 'home_aboutVideo', className: styles.aboutVideoWrap },
        React.createElement("div", { className: styles.aboutVideoContainer },
            React.createElement("h2", { className: styles.title },
                React.createElement("strong", null, "A quick video about CreditNinja")),
            React.createElement("div", { className: styles.text },
                React.createElement("p", null, "What is CreditNinja? Emergency Loans, FAST")),
            React.createElement("div", { className: styles.videoBlockContainer },
                React.createElement(YouTube, { videoId: 'o8reIQNlz48', opts: opts })),
            React.createElement("div", { className: styles.termsBlock, onClick: scrollIntoFooterView, onKeyDown: scrollIntoFooterView },
                React.createElement("span", null, "Terms and Conditions Apply")))));
};
AboutVideo.displayName = 'AboutVideo';
export default AboutVideo;
