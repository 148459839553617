import React, { memo } from 'react';
import Advantages from '@kmdpartners/ui-kit/src/CN_Design_System/components/Advantages';
import Button from '@kmdpartners/ui-kit/src/CN_Design_System/components/Button';
import makeBodyText from '@kmdpartners/ui-kit/src/CN_Design_System/utils/makeText';
import styles from './index.module.scss';
const ADVANTAGES = [
    {
        text: 'Easy online application process'
    },
    {
        text: 'If approved, you may get your money as soon as the same day*'
    },
    {
        text: 'Flexible repayment schedule'
    }
];
const Hero = memo(() => {
    return (React.createElement("section", { id: 'home_hero', className: styles.heroWrap },
        React.createElement("div", { className: styles.heroBackground },
            React.createElement("div", { className: styles.before }),
            React.createElement("div", { className: styles.after },
                React.createElement("div", { className: styles.heroAvatar }))),
        React.createElement("div", { className: styles.heroContainer },
            React.createElement("h1", { className: styles.title },
                React.createElement("strong", null, "Online personal loans with same day* decision")),
            React.createElement("div", { className: styles.bottomSection },
                React.createElement("h2", { className: styles.title }, "CreditNinja personal loans help with life\u2019s unexpected expenses."),
                React.createElement("div", { className: styles.featuresWrap },
                    React.createElement(Advantages, { items: ADVANTAGES })),
                React.createElement(Button, { ID: 'apply_now_button__hero', btnSize: 'L', isFullWidth: true, isBold: true, type: 'primaryBlue', label: 'Apply Now', href: '/registration?utm_source=dojo' }),
                React.createElement("div", { className: styles.descriptionBottom },
                    React.createElement("p", { className: `${makeBodyText('XL')} ${styles.text}` },
                        React.createElement("strong", null, "Can\u2019t get approved for traditional loans?"),
                        React.createElement("br", null),
                        "CreditNinja may have the solution you need."))))));
});
Hero.displayName = 'Hero';
export default Hero;
